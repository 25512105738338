import React, { useRef, useEffect } from 'react'
import Seo from "../components/seo"
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Header from '../components/Header'
import '../styles/reset.css'
import '../styles/global.css'
import * as styles from '../styles/project.module.css'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Tween, SplitWords } from 'react-gsap'

gsap.registerPlugin(ScrollTrigger);


function Project({ data }) {

    const subHeader = useRef();
    const topImage = useRef();
    const stackRef = useRef();

    const paraRefs = useRef([]);
    paraRefs.current = [];

    const imageRefs = useRef([]);
    imageRefs.current = [];

    useEffect(() => {
        const tl = gsap.timeline({duration: 1, delay: .3, ease: 'power2.in'});

        tl
            .from(subHeader.current, {
                y: 50,
            })
            .from(topImage.current, {
                autoAlpha: 0,
                y: 20
            }, '-=.5')
            .from(paraRefs.current, {
                opacity: 0,
                y: 30,
                stagger: .1,
            }, '-=.5')
    
        gsap.from(stackRef.current, 
            {   
                delay: 2,
                y: -10,
                autoAlpha: 0,
                duration: 1,
                ease: 'power2',
                scrollTrigger: {
                    trigger: stackRef.current.children[0],
                    toggleActions: 'play none none reverse',
                    scrub: 0.5
                }
            }
        )

        imageRefs.current.forEach((el, index) => {

            gsap.fromTo(el, {
              autoAlpha: 0,
              y: 20
            }, {
                y: 0,
                duration: 1, 
                autoAlpha: 1, 
                ease: "none",
                scrollTrigger: {
                    id: `section-${index+1}`,
                    trigger: el,
                    start: 'top center+=200',
                    toggleActions: 'play none none reverse',
                }
            })
      
        });
    
    }, []);

    const { slugs } = data
    const { htmlAst } = data.project
    const { 
        title, 
        stack, 
        image1, 
        image2, 
        image3, 
        image4, 
        type, 
        year,
        link,
        github, 
        order
    } = data.project.frontmatter



    const nextSlug = slugs.nodes[order === slugs.nodes.length ? 0 : order].frontmatter.slug

    const projectImage1 = getImage(image1)
    const projectImage2 = getImage(image2)
    const projectImage3 = getImage(image3)
    const projectImage4 = getImage(image4)

    let images = [ projectImage1, 
        projectImage3, 
        projectImage4 
    ]

    const addToParaRefs = (el) => {
        if (el && !paraRefs.current.includes(el)) {
          paraRefs.current.push(el)
        }
    }

    const addToImageRefs = (el) => {
        if (el && !imageRefs.current.includes(el)) {
          imageRefs.current.push(el)
        }
    }

    return (
        <div className="project">
            <Seo title={title} />
            <Header />
            <div className={styles.project}>

                <div style={{overflow: "hidden"}}>
                    <Tween from={{y: 90}} rotate={5} duration={1.5} delay={.7} ease="expo" stagger={0.1}>
                        <SplitWords
                            wrapper={<div style={{ display: 'inline-block', fontSize: '3em' }}/>}
                        >
                            {title}
                        </SplitWords>
                    </Tween>
                </div>
                <div style={{overflow: "hidden"}}>
                    <div className={styles.infoTop} ref={subHeader}>
                    <p>{type}, {year}</p>

                    <div>
                        <a href={github} target="_blank" rel="noreferrer">GitHub</a>
                        <a href={link} target="_blank" rel="noreferrer">Visit Site</a>
                    </div>
                    </div>
                </div>
                <div className={styles.topImg} ref={topImage}>
        
                    <GatsbyImage 
                        image={projectImage2} 
                        alt={title}
                        loading="lazy"
                    />
                </div>

                <div 
                    className={styles.mainInfo}
                >
                    {htmlAst.children
                        .filter(child => 
                            child.type === 'element'
                        )
                        .map((child, index) => (
                        <p key={index} ref={addToParaRefs}>{child.children[0].value}</p>
                    
                    ))}
                </div>

                <div className={styles.stack} ref={stackRef}>
                    <h3>Stack:</h3>
                    
                    <ul>
                        {stack.split(',').map(item => (
                            <li key={item}>{item}</li>
                        ))}
                    </ul>
                </div>

                {images.map((image, index) => (
                    <div 
                        className={styles.projectImg}
                        key={index}
                        ref={addToImageRefs}
                    >          
                        <GatsbyImage 
                            image={image} 
                            alt={title}
                            loading="lazy"
                        />
                    
                    </div>
                ))}

                <Link 
                    to={`/projects/${nextSlug}`}
                    className={styles.nextProject}
                >
                    Next Project
                </Link>
        
            </div>
        </div>
    )
}

export default Project

export const query = graphql`query ProjectPage($slug: String) {
    project: markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      htmlAst
      frontmatter {
        stack
        title
        type
        year
        link
        github
        order
        image1{
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: AUTO)
          }
        }
        image2 {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
        }
        image3 {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
        }
        image4 {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
        }
      }
    }
    slugs: allMarkdownRemark(sort: {fields: frontmatter___order}) {
        nodes {
          frontmatter {
            slug
            order
          }
        }
    }
  }  
`

